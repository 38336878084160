<script>

export default {
    methods: {
        back() {
            window.history.back();
        }
    },
};
</script>

<template>
    <Head :title="title" />
    <div class=" h-screen">
        <main class="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
            <div class="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
                <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#00966A] to-[#34D399] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
            </div>

            <div class="mx-auto mt-12 max-w-2xl text-center">
                <h2 class="text-2xl font-semibold h-fit bg-gradient-to-tr dark:from-emerald-600 dark:via-emerald-400 dark:to-emerald-300 from-emerald-700 via-emerald-500 to-emerald-400 inline-block text-transparent bg-clip-text">{{ $page.props.status }}</h2>
                <h1 class="mt-4 text-2xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100 sm:text-5xl">{{ title }}</h1>
                <p class="mt-4 text-base leading-7 text-zinc-600 dark:text-zinc-400 sm:mt-6 sm:text-lg sm:leading-8">{{ description}}</p>
            </div>
            <div class="mx-auto mt-16 flow-root max-w-3xl sm:mt-16">
                <h2 class="sr-only">Recommended links</h2>
                <GridBoxesErrorPage @link-clicked="(name) => linkClicked(name)" />
                <div class="mt-10 flex justify-center">
                    <button @click="back" class="text-sm font-semibold leading-6 text-zinc-600 dark:text-zinc-400">
                        <span aria-hidden="true">&larr;</span>
                        Go back
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>


<script setup>
import GridBoxesErrorPage from "../Comps/GridBoxesErrorPage.vue";
import { Head } from '@inertiajs/vue3';
import {computed} from "vue";

const props = defineProps({ status: Number })

const title = computed(() => {
    return {
        503: 'Service Unavailable',
        500: 'Server Error',
        404: 'Not Found',
        403: 'Forbidden',
    }[props.status]
})

const description = computed(() => {
    return {
        503: 'Synci is currently down for maintenance. Please check back soon.',
        500: 'Something went wrong on our end. We\'re looking into it.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
    }[props.status]
})
</script>

<style>
.bg-dots-darker {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
}
@media (prefers-color-scheme: dark) {
    .dark\:bg-dots-lighter {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
    }
}
</style>
