<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8">
        <Link :href="route('dashboard')" @click="$emit('linkClicked', 'Dashboard')" class="scale-100 !bg-opacity-80 p-6 bg-white dark:bg-card-dark dark:hover:bg-zinc-900 active:focus:scale-100 rounded-xl border border-zinc-800/10 dark:border-none dark:shadow-none flex motion-safe:hover:scale-[1.01] transition-all duration-250">
            <div>
                <div class="h-16 w-16 bg-emerald-100 dark:bg-emerald-800/20 flex items-center justify-center rounded-full">
                    <BookOpenIcon class="w-6 h-6 text-emerald-500" />
                </div>

                <h2 class="mt-6 text-xl font-semibold text-zinc-900 dark:text-white">Dashboard</h2>

                <p class="mt-4 text-zinc-500 dark:text-zinc-400 text-sm leading-relaxed">
                    Manage your budgets, sources, and transactions.
                </p>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="self-center shrink-0 stroke-emerald-500 w-6 h-6 mx-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
        </Link>

        <Link :href="route('home.contact')" @click="$emit('linkClicked', 'Help Center')" class="scale-100 !bg-opacity-80 p-6 bg-white dark:bg-card-dark dark:hover:bg-zinc-900 active:focus:scale-100 rounded-xl border border-zinc-800/10 dark:border-none dark:shadow-none flex motion-safe:hover:scale-[1.01] transition-all duration-250">
            <div>
                <div class="h-16 w-16 bg-emerald-100 dark:bg-emerald-800/20 flex items-center justify-center rounded-full">
                    <ChatBubbleBottomCenterTextIcon class="w-6 h-6 text-emerald-500" />
                </div>

                <h2 class="mt-6 text-xl font-semibold text-zinc-900 dark:text-white">Help Center</h2>

                <p class="mt-4 text-zinc-500 dark:text-zinc-400 text-sm leading-relaxed">
                    Find answers to common questions, or contact us directly.
                </p>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="self-center shrink-0 stroke-emerald-500 w-6 h-6 mx-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
        </Link>
    </div>
</template>

<script>
export default {
    name: "GridBoxes",
}
</script>

<script setup>
import {
    QueueListIcon,
    BookOpenIcon,
    ChatBubbleBottomCenterTextIcon,
    BuildingLibraryIcon,
} from '@heroicons/vue/24/outline'
import { Link } from '@inertiajs/vue3';
</script>
